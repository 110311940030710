import { template as template_c3908ef2d75c423797640de421c040c7 } from "@ember/template-compiler";
const WelcomeHeader = template_c3908ef2d75c423797640de421c040c7(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
