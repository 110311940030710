import { template as template_d4baf229fc6d4458ac8b4a4c1a463209 } from "@ember/template-compiler";
const SidebarSectionMessage = template_d4baf229fc6d4458ac8b4a4c1a463209(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
