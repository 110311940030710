import { template as template_327a6f80286a4d91bfaf6dfd123fdb5d } from "@ember/template-compiler";
const FKLabel = template_327a6f80286a4d91bfaf6dfd123fdb5d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
